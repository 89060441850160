<template>
  <section>
    <Header />
    <Navbar v-bind:linksExtended="linksExtended" :links="links" />
    <article class="faq__article">
      <p>Основания и порядок предоставления гражданства государства Россия</p>
      <p>
        Порядок приобретения гражданства России регламентируется ФЗ РФ «О
        гражданстве Российской Федерации». В соответствии с ним, основанием для
        получения гражданства РФ является:
      </p>
      <ul>
        <li>факт рождения на территории России (при определенных условиях)</li>
        <li>
          право восстановления в гражданстве лиц или граждан, ранее являющихся
          гражданами РФ
        </li>
        <li>желание стать гражданином РФ (при определенных условиях)</li>
        <li>
          иные основания, предусмотренные законодательством РФ, международными
          договоренностями
        </li>
      </ul>
      <p>
        Для граждан других государств и апатридов Российским законодательством
        предусмотрены два порядка получения гражданства РФ: общий и упрощенный.
      </p>
      <p>Алгоритм приобретения гражданства Российской Федерации:</p>
      <ul>
        <li>
          Иностранный гражданин, лицо или апатрид должен определить основания,
          достаточные для получения гражданства РФ. В соответствии с основаниями
          и собственным статусом, подготовить необходимые для получения
          гражданства РФ, документы
        </li>
        <li>Оплатить государственную пошлину</li>
        <li>
          Подать подготовленные документы в миграционное отделение МВД, ММЦ.
          После чего, получить на руки справку-подтверждение о принятии к
          рассмотрению заявления на получение гражданства, к рассмотрению
        </li>
      </ul>
      <p>Срок изучения предоставленных документов – 3-4 месяца.</p>
      <p>
        Условия, при которых иностранный гражданин, лицо или апатрид может
        рассчитывать на предоставление гражданства РФ:
      </p>
      <ul>
        <li>
          постоянное проживание на территории Российской Федерации в течение,
          минимум, 5 лет, с момента оформления РВП, вида на жительство.
          Допустимые сроки выезда за пределы РФ в указанный период – раз в год,
          не более чем на 3 месяца
        </li>
        <li>
          наличие законного, подтвержденного соответствующими документами,
          источника средств к существованию; владение на определенном уровне
          русским языком
        </li>
        <li>
          владение важными для Российской Федерации профессиональными навыками
          (для высококвалифицированных специалистов)
        </li>
        <li>
          проживание на территории России в течение, минимум, 1 года, в статусе
          беженца или переселенца
        </li>
      </ul>
      <p>
        В чем разница между получением гражданства России в общем порядке и в
        упрощенном?
      </p>
      <p>
        Реализовать первый вариант сложнее и дольше. Процедура получения
        гражданства России может растянуться на 3 года, и даже больше. Именно по
        этой причине, в момент выбора основания для приобретения гражданства РФ
        наши юристы рекомендуют, прежде всего, узнать, можете ли вы рассчитывать
        на получение гражданства РФ в упрощенном порядке.
      </p>
      <p>
        В соответствии с ФЗ РФ «О гражданстве в Российской Федерации», подающие
        заявление от подающего документы лица, сами документы на получение
        гражданства России, иностранные граждане и апатриды, обязаны:
      </p>
      <ul>
        <li>знать и соблюдать законы РФ</li>
        <li>знать и соблюдать Конституцию РФ</li>
        <li>
          подтвердить соответствующим сертификатом уровень владения русским
          языком
        </li>
        <li>пройти процедуру дактилоскопии</li>
      </ul>
      <p>
        С июля 2020 года граждане государств: Белоруссия, Молдова, Казахстан,
        Украина могут обращаться за получением гражданства России после
        получения вида на жительство.
      </p>
      <p>Как иностранному гражданину получить гражданство РФ (общий порядок)</p>
      <p>
        Процесс осуществляется в несколько этапов. Важно, чтобы все они были
        пройдены:
      </p>
      <ul>
        <li>
          иностранный гражданин должен законным путем пересечь границу РФ,
          получить и заполнить миграционную карту; стать на миграционный учет
        </li>
        <li>оформить РВП (кроме граждан Белоруссии)</li>
        <li>получить вид на жительство на территории России</li>
        <li>
          собрать и подать в соответствующий орган необходимые для получения
          гражданства документы
        </li>
      </ul>
      <p>
        Относительно последнего пункта. Сбор и подача необходимых документов на
        получение гражданства в общем порядке, осуществляется по прошествии 5
        лет после постоянного проживания в России, на основании вида на
        жительство.
      </p>
      <p>Перечень необходимых документов:</p>
      <ul>
        <li>
          заявление от иностранного гражданина, апатрида (подается в 2-х
          экземплярах)
        </li>
        <li>
          удостоверение личности и документ-свидетельство о рождении; документ о
          виде на жительство (подается копия); оплаченная квитанция госпошлины
        </li>
        <li>
          при наличии – свидетельства о браке и разводе (если было несколько,
          предоставить все)
        </li>
        <li>
          фото 3Х4 матовые (относительно цветного или черно-белого изображения,
          рекомендаций со стороны законодательства, нет)
        </li>
        <li>
          свидетельство об образовании, выданное до 1991 года или сертификат,
          подтверждающий уровень владения русским языком
        </li>
      </ul>
      <p>Кому нужен экзамен по русскому языку?</p>
      <p>
        Граждане государства Белоруссия являются носителями русского языка. Им
        экзамен не требуется. Также, не требуется экзамен инвалидам I группы,
        гражданам, признанным недееспособными, женщинам после 60 лет, мужчинам
        после 65 лет. Гражданам, получившим среднее образование в РФ после 1991
        года, среднее образование на территории СССР, до момента его распада,
        произошедшего в 1991 году.
      </p>
      <p>
        Граждане, подпадающие под вышеперечисленные категории, от экзамена
        освобождаются. Подтверждение уровня владения русским языком им не
        требуется.
      </p>
    </article>
    <Footer />
  </section>
</template>

<script>
import Header from "../Header.vue";
import Navbar from "../Navbar.vue";
import Footer from "../Footer.vue";

export default {
  name: "C1",
  components: { Header, Navbar, Footer },
  data() {
    return {
      linksExtended: [
        {
          id: "Экзамен",
          text: "Экзамен",
          sublinks: [
            {
              id: "Для патента",
              text: "Для патента",
              link: "/dlya-patenta",
            },
            {
              id: "Для РВП",
              text: "Для РВП",
              link: "/dlya-rvp",
            },
            {
              id: "Для ВНЖ",
              text: "Для ВНЖ",
              link: "/dlya-vnj",
            },
            // {
            //   id: "Для гражданства",
            //   text: "Для гражданства",
            //   link: "/dlya-grajdanstva",
            // },
          ],
        },
        {
          id: "РВП",
          text: "РВП",
          sublinks: [
            {
              id: "Основания получения РВП",
              text: "Основания получения РВП",
              link: "/osnovaniya-polucheniya-rvp",
            },
            {
              id: "РВП по квоте",
              text: "РВП по квоте",
              link: "/rvp-po-kvote",
            },
            {
              id: "РВП без квоты",
              text: "РВП без квоты",
              link: "/rvp-bez-kvoti",
            },
            {
              id: "РВП без КВОТЫ (Украина, Молдова, Казахстан )",
              text: "РВП без КВОТЫ (Украина, Молдова, Казахстан )",
              link: "/rvp-bez-kvoti-dop",
            },
          ],
        },
        {
          id: "ВНЖ",
          text: "ВНЖ",
          sublinks: [
            {
              id: "Основания получения ВНЖ",
              text: "Основания получения ВНЖ",
              link: "/osnovaniya-polucheniya-vnj",
            },
            {
              id: "ВНЖ в упрощенном порядке",
              text: "ВНЖ в упрощенном порядке",
              link: "/vnj-v-uproshennom-poryadke",
            },
          ],
        },
        {
          id: "НРЯ",
          text: "НРЯ",
          sublinks: [
            {
              id: "Основания получения НРЯ",
              text: "Основания получения НРЯ",
              link: "/osnovaniya-polucheniya-nrya",
            },
          ],
        },
        {
          id: "Гражданство",
          text: "Гражданство",
          sublinks: [
            {
              id: "Основания для получения гражданства РФ",
              text: "Основания для получения гражданства РФ",
              link: "/osnovaniya-dlya-polucheniya-grajdanstva-rf",
            },

            {
              id: "Гражданство по НРЯ",
              text: "Гражданство по НРЯ",
              link: "/grajdanstvo-po-nrya",
            },
            {
              id: "Гражданство РФ для детей",
              text: "Гражданство РФ для детей",
              link: "/grajdanstvo-rf-dlya-detei",
            },
            {
              id: "Гражданство РФ по браку",
              text: "Гражданство РФ по браку",
              link: "/grajdanstvo-rf-po-braku",
            },
          ],
        },
      ],
      links: [
        {
          id: "О нас",
          text: "О нас",
          link: "/o-nas",
        },
        {
          id: "Контакты",
          text: "Контакты",
          link: "/kontakti",
        },
      ],
      cards: [
        {
          id: 1,
          title: "Перевод документов",
          price: "от 700₽",
          list: [
            "Переводы документов (количество требуемых на подачу)",
            "Заявление в 2 –х экземплярах",
            "Экзамен на знание русского языка",
            "Проверка документов (на сроки и.т.п)",
            "Заполнение автобиографии",
            "Сопровождение до сдачи документов",
            "Консультация по любым вопросам",
          ],
        },
        { id: 2, title: "РВП/ВНЖ", price: "25000₽" },
        // { id: 3, title: "ВНЖ", price: "25000₽" },
        { id: 3, title: "Гражданство", price: "15000₽" },
        { id: 4, title: "Квота", price: "6000₽" },
        {
          id: 5,
          title: "Экзамен ( РВП,ВНЖ,Гражданство ,Патент )",
          price: "от 3500₽",
        },
      ],
      reasons: [
        {
          id: "economy",
          img: "economy.svg",
          title: "Экономия",
          paragraph: "Самые низкие цены в регионе",
        },

        {
          id: "office",
          img: "office.svg",
          title: "Офис рядом с ОВМ",
          paragraph: "Находимся в 100 м от отдела по вопросам миграции",
        },
        {
          id: "guarantee",
          img: "guarantee.svg",
          title: "Гарантия качества",
          paragraph: "Оказываем все услуги в установленный срок",
        },
        {
          id: "folder",
          img: "folder.svg",
          title: "Полный комплекс услуг",
          paragraph: "Любые миграционные услуги в одном месте",
        },
        {
          id: "card",
          img: "card.svg",
          title: "Прозрачная оплата",
          paragraph: "Оплата за фактические услуги. Без скрытых платежей",
        },
      ],
      reviews: [
        {
          id: "Владимир Суховеров",
          image: "suhoverov.jpg",
          text:
            "Готовили документы для вида на жительство. Все сделано професиионально, в короткие сроки без лишних слов и суеты, на все вопросы всегда получал исчерпывающие ответы. Особо хочу поблагодарить Кристину Максимовну и Марию Витальевну!!!! Очень хорошие специалисты, рекомендую! Друзья белорусы делали у них ВНЖ, тоже остались очень довольны работой. Документы подал с первого раза!!",
          name: "Владимир Суховеров",
        },
        {
          id: "Ольга С.",
          image: "olga.jpg",
          text:
            "Делали документы для ВНЖ гражданке Беларусь. Все очень быстро и качественно, девочки работают с каждым клиентом индивидуально, помнят о вас и ведут до сдачи документов, помогут во всем разобраться и все разжуют. Следующий этап гражданство, только к ним и только с ними. Спасибо, что в нашем районе есть такой центр. Вы лучшие!!!",
          name: "Ольга С.",
        },
        {
          id: "Алексей Журавель",
          image: "juravel.jpg",
          text:
            "Подавал документы на ВНЖ. Очень вежливые и грамотные специалисты. Помогают и консультируют по всем вопросам, возникающим в процессе оформления документов. Все вопросы стараются решить максимально быстро. P.S. Самые доступные цены на переводы документов по Подольску.",
          name: "Алексей Журавель",
        },
        {
          id: "Лиза Пронько",
          image: "pronko.webp",
          text:
            "Огромное спасибо хочу сказать Марии! Сначала она помогала с оформлением документов ВНЖ и на гражданство моего мужа, а теперь мне помогает в оформлении РВП. Буду и дальше с ней сотрудничать. Мария ответственный и хороший специалист!",
          name: "Лиза Пронько",
        },
        {
          id: "Елена Ч.",
          image: "cherkizova.jpg",
          text:
            "Очень пожалели, что уже на последнем этапе оформления открыли для себя этот миграционный центр. Девочки, почему не раньше, мы через такие муки ада прошли с РВП, потом с ВНЖ и наконец встретили вас.Спасибо большое за помощь, доброжелательность, компетентную консультацию и сбор всех необходимых документов. На гражданство подали с первого раза. Очень вам благодарны, особенно Кристиночке, которая переживала за нас больше всех. Всем советую, не теряйте время и свои нервы (здоровье важнее), только к ним.",
          name: "Елена Ч.",
        },
        {
          id: "Вероника А.",
          image: "vera.webp",
          text:
            "Выражаю благодарность Кристине за профессионализм и оперативную помощь при подготовке анкеты на гражданство РФ. Приняли документы с первого раза. От общения с Кристиной остались самые приятные впечатления.",
          name: "Вероника А.",
        },
        {
          id: "Sky17 R.",
          image: "sky.jpg",
          text:
            "Обслуживание клиентов на высоте. Подавал документы на гражданство, выполнено на все 100%, придраться не к чему. Главное вежливые и старательно выполняют свою работу. Лучшие в Москве и области, советую.",
          name: "Sky17 R.",
        },
        {
          id: "Павел Савенок",
          image: "pavel.jpg",
          text:
            "Девочки доброжелательные, особо хочу выделить Кристину Максимовну, старательная девочка, всегда поможет и сделает все как надо) спасибо",
          name: "Павел Савенок",
        },
      ],
    };
  },
};
</script>

<style></style>
